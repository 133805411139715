import './App.css';
import {useRef, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Home from './Home';
import Analysis from './Components/Analysis';
import Data from './Components/Data';
import Login from './Components/Login';
import Register from './Components/Register';
import Profile from './Components/Profile';
import Landing from './Components/Landing'
import Video from './Components/Video';

function App() {

return (
<Router>
<div>
  <Routes>
    <Route path="/home" element={<Analysis />} />
    <Route path="/analysis" element={<Analysis />} />
    <Route path="/video" element={<Video />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/data" element={<Data />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/landing" element={<Landing />} />
    <Route path="/" element={<Landing />} />
  </Routes>
</div>
</Router>
);

}

export default App;