import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Landing.css';

function Landing() {
  const navigate = useNavigate();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTermsOfService, setTermsOfService] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const avatars = [
    { id: 1, name: "Avatar1", image: "/avatar-1.png", modalImage: "/horizontal-avatar-1.png", description: "Talk to Avatar1" },
    { id: 2, name: "Avatar2", image: "/avatar-2.png", modalImage: "/horizontal-avatar-2.png", description: "Talk to Avatar2" },
    { id: 3, name: "Avatar3", image: "/avatar-3.png", modalImage: "/horizontal-avatar-3.png", description: "Talk to Luna" },
    { id: 4, name: "Avatar4", image: "/avatar-4.png", modalImage: "/horizontal-avatar-4.png", description: "Talk to Avatar4" },
  ];

  const openAvatarModal = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const closeAvatarModal = () => {
    setSelectedAvatar(null);
  };

  return (
    <div className="landing-container">
      <header className="landing-header">
        <img src="/logo.png" className="landing-logo" alt="Logo" />
        <div className="page-container">
          <p className="p"><strong>Home</strong></p>
          <p className="p">History</p>
          <p className="p" onClick={() => navigate('/login')}>Login</p>
          <button className="sign-up-button" onClick={() => navigate('/register')}>Sign Up</button>
        </div>
      </header>
      <div className="echoAI-container">
        <div className="title-container">
          <p className="echo-title">EchoAI</p>
          <p className="subtitle">Uncover magic in every conversation: An AI that understands you like no one else.</p>
          <button className="sign-up-button" onClick={() => navigate('/video')}>Try EchoAI free</button>
        </div>
        <img src="/hedra.png" className="hedra" alt="Hedra" />
      </div>
      <div className="pick-container">
        <p className="p" style={{ padding: "0 20%" }}><strong>Pick who you want to speak with.</strong></p>
        <div className="avatar-container">
          <img src="/left-arrow.png" className="arrow" alt="Left Arrow" />
          {avatars.map((avatar) => (
            <img
              key={avatar.id}
              src={avatar.image}
              className="avatar"
              alt={avatar.name}
              onClick={() => openAvatarModal(avatar)}
            />
          ))}
          <img src="/right-arrow.png" className="arrow" alt="Right Arrow" />
        </div>
        <p style={{ padding: "0 20%", marginTop: "3%" }}>
          By proceeding, I accept the <strong style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setTermsOfService(true)}>Terms of Service</strong>. Please read our <strong style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setShowDisclaimer(true)}>Disclaimer</strong> here.
        </p>

        {/* Avatar Modal */}
{/* Avatar Modal */}
{selectedAvatar && (
  <div className="modal-overlay" onClick={closeAvatarModal}>
    <div className="avatar-modal-content" onClick={(e) => e.stopPropagation()}>
      <div className="avatar-modal-image">
        <img src="/left-arrow.png" className="arrow" style={{ height: '25px' }} alt="Left Arrow" />
        <img src={selectedAvatar.modalImage} alt={selectedAvatar.name} className="modal-avatar" />
        <img src="/right-arrow.png" className="arrow" style={{ height: '25px' }} alt="Right Arrow" />
      </div>
      <button className="avatar-button">{selectedAvatar.description}</button>
      
      {/* Avatar selection below */}
      <div className="avatar-selection-container">
        {avatars.map((avatar) => (
          <img
            key={avatar.id}
            src={avatar.image}
            alt={avatar.name}
            className={`avatar-option ${selectedAvatar.id === avatar.id ? 'selected-avatar' : ''}`}
            onClick={() => setSelectedAvatar(avatar)}
          />
        ))}
      </div>
    </div>
  </div>
)}

        {/* Disclaimer Modal */}
        {showDisclaimer && (
          <div className="modal-overlay" onClick={() => setShowDisclaimer(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h2>Disclaimer</h2>
              <p style={{color:"red"}}>updated December 3, 2024</p>
              <p>This application is designed for interactive engagement and emotional analysis purposes. By using this app, you consent to the collection of personal information, 
              including but not limited to your email address, age, and recordings of your facial expressions. 
              These recordings will be used to analyze your emotions through our proprietary technology.</p>
              <p>Please note that the emotional analysis provided by this app is not intended as a substitute for professional advice, such as medical, psychological, or legal consultation. 
              Users should not rely solely on the app's results for making significant decisions regarding their health, relationships, or well-being.</p>
              <p>By using this app, you acknowledge that you are voluntarily providing your information and facial data, and you consent to the analysis of your emotional responses. 
              You also agree to hold the app developer and its affiliates harmless from any claims, losses, or damages arising from your use of the app.</p>
              <div className="modal-button-container">
                <button className="accept-button" onClick={() => setShowDisclaimer(false)}>Accept</button>
                <button className="decline-button" onClick={() => setShowDisclaimer(false)}>Decline</button>
              </div>
            </div>
          </div>
        )}

        {/* Terms of Service Modal */}
        {showTermsOfService && (
          <div className="modal-overlay" onClick={() => setTermsOfService(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h2>Terms of Service</h2>
              <p>1. Acceptance of Terms<br/>By accessing or using this app, you agree to these Terms of Service. If you do not agree, please discontinue using the app.</p>
              <p>2. User Conduct<br/>You agree not to use the app for any unlawful or prohibited purposes. You are responsible for any content you upload or submit through the app.</p>
              <p>3. Consent for Data Collection<br/>By using this app, you consent to the collection, use, and analysis of your personal data, including recordings of your facial expressions for emotional analysis. 
              All data will be processed in accordance with our privacy policy.</p>
              <p>4. Intellectual Property<br/>All content and intellectual property within the app, including but not limited to software, graphics, and text, are owned by the developer. You may not reproduce or 
              distribute any part of the app without explicit permission.</p>
              <p>5. Disclaimer of Warranties<br/>This app is provided "as is" without any warranties, expressed or implied. The developer does not guarantee that the app will function error-free or without interruption.</p>
              <p>6. Limitation of Liability<br/>In no event shall the developer be liable for any indirect or consequential damages resulting from the use of the app, including but not limited to loss of data or emotional distress.</p>
              <p>7. Governing Law<br/>These terms are governed by the laws of [State/Country]. Any legal action or dispute will be settled within the jurisdiction of this location.</p>
              <p>8. Changes to Terms<br/>We may revise these terms at any time. It is your responsibility to review the current version regularly.</p>
              <p>9. Contact Information<br/>For inquiries regarding these Terms of Service, please contact [Your Contact Information].</p>
              <div className="modal-button-container">
                <button className="accept-button" onClick={() => setTermsOfService(false)}>Accept</button>
                <button className="decline-button" onClick={() => setTermsOfService(false)}>Decline</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Landing;
