import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../Styles/Register.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setIsEmailValid(emailPattern.test(emailValue));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    // Create a user object
    const user = { username, password, email };

    try {
      const response = await fetch('http://127.0.0.1:5000/user/user_register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        console.log('User added to the database:', user);
        navigate('/login');
      } else {
        const errorData = await response.json();
        console.error('Error registering user:', errorData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="register-container"> 
      <div className="header">
        <Link to="/">
          <img src="/logo.png" className="register-logo" alt="Logo" />
        </Link>
      </div>
      <div className="Register">
        <div className="account">
          <h2>Create Account</h2>
          <div className="required">
            <p className="fields">All fields are required.</p>
          </div>
          <form onSubmit={handleRegister}>
            <div className="input-container">
              <input type="text" value={first_name} placeholder="First Name" className="name" onChange={(e) => setFirstName(e.target.value)} required />
              <input type="text" value={last_name} placeholder="Last Name" className="name" onChange={(e) => setLastName(e.target.value)} required />
            </div>
            <div>
              <input type="email" value={email} placeholder="Email" onChange={handleEmailChange} required className={isEmailValid ? '' : 'invalid'} />
              {!isEmailValid && <p className="error-message">Please enter a valid email address</p>}
            </div>
            <div>
              <input type="text" value={username} placeholder="Username" onChange={(e) => setUsername(e.target.value)} required />
            </div>
            <div className="password-container">
              <input type={showPassword ? "text" : "password"} value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
              {!showPassword ? (
                <img src="/eye.png" className="eye" alt="Eye" onClick={togglePasswordVisibility} />
              ) : (
                <img src="/hidden-eye.png" className="eye" alt="Eye" onClick={togglePasswordVisibility} />
              )}
            </div>
            <button type="submit">Register</button>
            <div className="create-account">
              <p>Already have an account?</p>
              <Link to="/login" style={{ textDecoration: 'underline' }}>
                <p style={{ fontWeight: 'bold'}}>Click to login</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
