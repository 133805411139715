import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Profile.css';

function Profile() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const googleUser = JSON.parse(localStorage.getItem('googleUser'));
    
    if (googleUser) {
      setUserData({
        name: `${googleUser.firstName} ${googleUser.lastName}`,
        email: googleUser.email,
        profilePicture: googleUser.profilePicture,
        platform: 'Google'
      });
    } else {
      // If no user data is found
      navigate('/login'); // Redirect to login
    }
  }, [navigate]);

  const handleGoogleLogout = () => {
    localStorage.removeItem('googleUser');
    navigate('/login'); 
  };

  return (
    <div className="profile-bg-container">
      <div className="header">
        <Link to="/">
          <img src="/logo.png" className="profile-logo" alt="Logo" />
        </Link>
      </div>
      <div className="profile">
        <h3 className="profile-text">Profile</h3>
        <div className="profile-container">
          {userData && (
            <>
              <img src={userData.profilePicture} className="main-user" alt="User" />
              <h3>{userData.name}</h3>
              <div className="email-container">
                <img src="/email.png" className="email" alt="Email" />
                <p style={{ fontSize: '18px' }}>{userData.email}</p>
              </div>
              <button onClick={handleGoogleLogout}>Logout</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
