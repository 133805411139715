// import React, { useState, useEffect, useRef } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import EmojiPicker from 'emoji-picker-react';
// import '../Styles/Video.css';

// function Video() {
//   const navigate = useNavigate();
//   const [messages, setMessages] = useState([{ text: 'Welcome to EchoAI! Start chatting to begin.', sender: 'assistant' }]);
//   const [inputMessage, setInputMessage] = useState('');
//   const [isWebcamActivated, setIsWebcamActivated] = useState(false);
//   const [hasStarted, setHasStarted] = useState(false);
//   const [isRecording, setIsRecording] = useState(false);
//   const typingTimeoutRef = useRef(null);
//   const sendTimeoutRef = useRef(null);
//   const messagesEndRef = useRef(null);
//   const [userData, setUserData] = useState(null);
//   const [isTyping, setIsTyping] = useState(false);
//   const [videoStream, setVideoStream] = useState(null);

//   useEffect(() => {
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [messages]);

//   // load user data from localStorage
//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     const googleUser = JSON.parse(localStorage.getItem('googleUser'));
//     if (user) {
//       setUserData(user);
//     } else if (googleUser) {
//       setUserData(googleUser);
//     }
//   }, []); 

//   const chatStart = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/chat/start', { method: 'POST' });
//       if (response.ok) {
//         setHasStarted(true);
//         console.log("Started chat");
//       } else {
//         console.error('Failed to start chat');
//       }
//     } catch (error) {
//       console.error('Error starting chat:', error);
//     }
//   };


//   const stopRecording = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/user/stop_record', { method: 'POST' });
//       if (response.ok) {
//         setIsRecording(false);
//         console.log('Stopped recording');
//       } else {
//         console.error('Failed to stop recording');
//       }
//     } catch (error) {
//       console.error('Error stopping recording:', error);
//     }
//   };
  
//   const activateWebcam = async () => {
//     if (!videoStream) { // Prevent reinitialization
//       try {
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         setVideoStream(stream);
//         setIsWebcamActivated(true);
//       } catch (error) {
//         console.error('Error accessing webcam:', error);
//         alert('Could not access the webcam.');
//       }
//     }
//   };
  
//   const deactivateWebcam = () => {
//     if (videoStream) {
//       videoStream.getTracks().forEach((track) => track.stop());
//       setIsWebcamActivated(false);
//     }
//   };
//   const startRecording = async () => {
//     if (!isWebcamActivated) {
//       console.error("Cannot start recording: Webcam is not activated.");
//       return;
//     }
//     try {
//       const response = await fetch('http://127.0.0.1:5000/user/start_record', { method: 'POST' });
//       if (response.ok) {
//         setIsRecording(true);
//         console.log('Started recording');
//       } else {
//         console.error('Failed to start recording');
//       }
//     } catch (error) {
//       console.error('Error starting recording:', error);
//     }
//   };
  
//   // const handleInputChange = (e) => {
//   //   setInputMessage(e.target.value);
//   //   if (isWebcamActivated && !isRecording) {
//   //     console.log("recording working");
//   //     startRecording();
//   //   }
//   //   clearTimeout(typingTimeoutRef.current);
//   //   typingTimeoutRef.current = setTimeout(() => {
//   //     stopRecording();
//   //   }, 2800);
//   // };
//   const handleInputChange = (e) => {
//     setInputMessage(e.target.value);
  
//     if (!isRecording && videoStream) {
//       startRecording();
//     }
  
//     clearTimeout(typingTimeoutRef.current);
//     typingTimeoutRef.current = setTimeout(() => {
//       if (isRecording) {
//         stopRecording();
//       }
//     }, 2000);
//   };
//       const handleSendMessage = async (e) => {
//     e.preventDefault();
//     if (inputMessage.trim() !== '') {
//       setMessages([...messages, { text: inputMessage, sender: 'user' }]);
//       setInputMessage('');

//       // buffer messages and send them in one go after delay
//       clearTimeout(sendTimeoutRef.current);
//       sendTimeoutRef.current = setTimeout(async () => {
//         await sendBufferedMessages();
//         stopRecording(); 
//       }, 2800);
//     }
//   };
  
    
//   async function sendBufferedMessages() {
//     try {
//       setIsTyping(true); // Show typing indicator
//       const bufferResponse = await fetch(
//         `http://127.0.0.1:5000/text/buffer?user_input=${encodeURIComponent(inputMessage)}`,
//         { method: 'POST' }
//       );
  
//       if (bufferResponse.ok) {
//         const replyResponse = await fetch('http://127.0.0.1:5000/text/get_reply', { method: 'GET' });
//         if (replyResponse.ok) {
//           const replyText = await replyResponse.text();
//           setMessages((prevMessages) => [
//             ...prevMessages,
//             { text: replyText, sender: 'assistant' },
//           ]);
//         } else {
//           console.error('Failed to fetch response from backend');
//         }
//       } else {
//         console.error('Failed to buffer user input');
//       }
//     } catch (error) {
//       console.error('Error during message handling:', error);
//     } finally {
//       setIsTyping(false);
//     }
//   }
    

//   return (
//     <div className="video">
//       <header className="analysis-header">
//         <Link to="/">
//           <img src="/logo.png" alt="Logo" className="analysis-logo" />
//         </Link>
//         {/* {userData ? (
//           <img
//             src="/user-icon.png"
//             alt="Profile"
//             className="profile-picture"
//             onClick={() => navigate('/profile')}
//             style={{ cursor: 'pointer' }}
//           />
//         ) : (
//           <div className="sign-in-analysis" onClick={() => navigate('/login')}>Sign In</div>
//         )} */}
//       </header>
//       <div className="analysis-body">
//             <div className="video-container">
//               {!isWebcamActivated ? (
//                 <div className="deactivate-video-container">
//                   <div className="placeholder-container">
//                     <p style={{color:"#8C8C8C"}}>Webcam is not activated.</p>
//                   </div>
//                 </div>
//               ) : (
//                 <div className="activate-video-container">
//                     <video
//                       ref={(videoElement) => {
//                         if (videoElement && !videoElement.srcObject) {
//                           videoElement.srcObject = videoStream;
//                           videoElement.play();
//                         }
//                       }}
//                       className="video-feed"
//                     ></video>
//                   <div className="corner top-left"></div>
//                   <div className="corner top-right"></div>
//                   <div className="corner bottom-left"></div>
//                   <div className="corner bottom-right"></div>
//                 </div>
//               )}
//             </div>
//       </div>
//       <button className="done-button">Done</button>
//     </div>
//   );
// }

// export default Video;

// import React, { useState, useEffect, useRef } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import '../Styles/Video.css';

// function Video() {
//   const navigate = useNavigate();
//   const [messages, setMessages] = useState([{ text: 'Welcome to EchoAI! Start chatting to begin.', sender: 'assistant' }]);
//   const [inputMessage, setInputMessage] = useState('');
//   const [isWebcamActivated, setIsWebcamActivated] = useState(false);
//   const [hasStarted, setHasStarted] = useState(false);
//   const [isRecording, setIsRecording] = useState(false);
//   const typingTimeoutRef = useRef(null);
//   const sendTimeoutRef = useRef(null);
//   const messagesEndRef = useRef(null);
//   const [userData, setUserData] = useState(null);
//   const [isTyping, setIsTyping] = useState(false);
//   const [videoStream, setVideoStream] = useState(null);

//   useEffect(() => {
//     // Automatically scroll to the latest message
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [messages]);

//   // Load user data from localStorage
//   useEffect(() => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     const googleUser = JSON.parse(localStorage.getItem('googleUser'));
//     if (user) {
//       setUserData(user);
//     } else if (googleUser) {
//       setUserData(googleUser);
//     }
//   }, []);

//   // Ask for webcam activation on page load
//   useEffect(() => {
//     activateWebcam();
//   }, []);

//   const chatStart = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/chat/start', { method: 'POST' });
//       if (response.ok) {
//         setHasStarted(true);
//         console.log("Started chat");
//       } else {
//         console.error('Failed to start chat');
//       }
//     } catch (error) {
//       console.error('Error starting chat:', error);
//     }
//   };

//   const stopRecording = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:5000/user/stop_record', { method: 'POST' });
//       if (response.ok) {
//         setIsRecording(false);
//         console.log('Stopped recording');
//       } else {
//         console.error('Failed to stop recording');
//       }
//     } catch (error) {
//       console.error('Error stopping recording:', error);
//     }
//   };

//   const activateWebcam = async () => {
//     if (!videoStream) { // Prevent reinitialization
//       try {
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         setVideoStream(stream);
//         setIsWebcamActivated(true);
//       } catch (error) {
//         console.error('Error accessing webcam:', error);
//         alert('Could not access the webcam.');
//       }
//     }
//   };

//   const deactivateWebcam = () => {
//     if (videoStream) {
//       videoStream.getTracks().forEach((track) => track.stop());
//       setVideoStream(null);
//       setIsWebcamActivated(false);
//     }
//   };

//   const startRecording = async () => {
//     if (!isWebcamActivated) {
//       console.error("Cannot start recording: Webcam is not activated.");
//       return;
//     }
//     try {
//       const response = await fetch('http://127.0.0.1:5000/user/start_record', { method: 'POST' });
//       if (response.ok) {
//         setIsRecording(true);
//         console.log('Started recording');
//       } else {
//         console.error('Failed to start recording');
//       }
//     } catch (error) {
//       console.error('Error starting recording:', error);
//     }
//   };

//   const handleInputChange = (e) => {
//     setInputMessage(e.target.value);

//     if (!isRecording && videoStream) {
//       startRecording();
//     }

//     clearTimeout(typingTimeoutRef.current);
//     typingTimeoutRef.current = setTimeout(() => {
//       if (isRecording) {
//         stopRecording();
//       }
//     }, 2000);
//   };

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     if (inputMessage.trim() !== '') {
//       setMessages([...messages, { text: inputMessage, sender: 'user' }]);
//       setInputMessage('');

//       // Buffer messages and send them in one go after delay
//       clearTimeout(sendTimeoutRef.current);
//       sendTimeoutRef.current = setTimeout(async () => {
//         await sendBufferedMessages();
//         stopRecording(); 
//       }, 2800);
//     }
//   };

//   async function sendBufferedMessages() {
//     try {
//       setIsTyping(true); // Show typing indicator
//       const bufferResponse = await fetch(
//         `http://127.0.0.1:5000/text/buffer?user_input=${encodeURIComponent(inputMessage)}`,
//         { method: 'POST' }
//       );

//       if (bufferResponse.ok) {
//         const replyResponse = await fetch('http://127.0.0.1:5000/text/get_reply', { method: 'GET' });
//         if (replyResponse.ok) {
//           const replyText = await replyResponse.text();
//           setMessages((prevMessages) => [
//             ...prevMessages,
//             { text: replyText, sender: 'assistant' },
//           ]);
//         } else {
//           console.error('Failed to fetch response from backend');
//         }
//       } else {
//         console.error('Failed to buffer user input');
//       }
//     } catch (error) {
//       console.error('Error during message handling:', error);
//     } finally {
//       setIsTyping(false);
//     }
//   }

//   return (
//     <div className="video">
//       <header className="analysis-header">
//         <Link to="/">
//           <img src="/logo.png" alt="Logo" className="analysis-logo" />
//         </Link>
//       </header>
//       <div className="analysis-body">
//         <div className="video-container">
//           {!isWebcamActivated ? (
//             <div className="deactivate-video-container">
//               <div className="placeholder-container">
//                 <p style={{ color: "#8C8C8C" }}>Webcam is not activated.</p>
//               </div>
//             </div>
//           ) : (
//             <div className="activate-video-container">
//               <video
//                 ref={(videoElement) => {
//                   if (videoElement && !videoElement.srcObject) {
//                     videoElement.srcObject = videoStream;
//                     videoElement.play();
//                   }
//                 }}
//                 className="video-feed"
//               ></video>
//               <div className="corner top-left"></div>
//               <div className="corner top-right"></div>
//               <div className="corner bottom-left"></div>
//               <div className="corner bottom-right"></div>
//             </div>
//           )}
//         </div>
//       </div>
//       <button className="done-button">Done</button>
//     </div>
//   );
// }

// export default Video;
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Video.css';

function Video() {
  const navigate = useNavigate();
  const [isWebcamActivated, setIsWebcamActivated] = useState(false);
  const [videoStream, setVideoStream] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(true); // To show the custom alert

  const activateWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setVideoStream(stream);
      setIsWebcamActivated(true);
    } catch (error) {
      console.error('Error accessing webcam:', error);
      alert('Could not access the webcam.');
    }
  };

  const deactivateWebcam = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
      setIsWebcamActivated(false);
    }
  };

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    activateWebcam();
  };

  const handleDecline = () => {
    setShowConfirmationModal(false);
    console.log('Webcam activation declined');
  };

  return (
    <div className="video">
      <header className="analysis-header">
        <Link to="/">
          <img src="/logo.png" alt="Logo" className="analysis-logo" />
        </Link>
      </header>
      <div className="analysis-body">
        {showConfirmationModal && (
          <div className="modal-overlay">
            <div className="video-modal">
              <p>Allow EchoAI to access video on your device?</p>
              <div className="modal-buttons">
                <button onClick={handleConfirm}>Yes</button>
                <button onClick={handleDecline}>No</button>
              </div>
            </div>
          </div>
        )}
        <div className="video-container">
          {!isWebcamActivated ? (
            <div className="deactivate-video-container">
              <div className="placeholder-container">
                <p style={{ color: "#8C8C8C" }}>Webcam is not activated.</p>
              </div>
            </div>
          ) : (
            <div className="activate-video-container">
              <video
                ref={(videoElement) => {
                  if (videoElement && !videoElement.srcObject) {
                    videoElement.srcObject = videoStream;
                    videoElement.play();
                  }
                }}
                className="video-feed"
              ></video>
              <div className="corner top-left"></div>
              <div className="corner top-right"></div>
              <div className="corner bottom-left"></div>
              <div className="corner bottom-right"></div>
            </div>
          )}
        </div>
      </div>
      <button className="done-button" onClick={deactivateWebcam}>Done</button>
    </div>
  );
}

export default Video;
