import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/Analysis.css';
import EmojiPicker from 'emoji-picker-react';

function Analysis() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([{ text: 'Welcome to EchoAI! Start chatting to begin.', sender: 'assistant' }]);
  const [inputMessage, setInputMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isWebcamActivated, setIsWebcamActivated] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const typingTimeoutRef = useRef(null);
  const sendTimeoutRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [videoStream, setVideoStream] = useState(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // load user data from localStorage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const googleUser = JSON.parse(localStorage.getItem('googleUser'));
    if (user) {
      setUserData(user);
    } else if (googleUser) {
      setUserData(googleUser);
    }
  }, []); 

  const chatStart = async () => {
    try {
      const response = await fetch('http://127.0.0.1:5000/chat/start', { method: 'POST' });
      if (response.ok) {
        setHasStarted(true);
        console.log("Started chat");
      } else {
        console.error('Failed to start chat');
      }
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };


  const stopRecording = async () => {
    try {
      const response = await fetch('http://127.0.0.1:5000/user/stop_record', { method: 'POST' });
      if (response.ok) {
        setIsRecording(false);
        console.log('Stopped recording');
      } else {
        console.error('Failed to stop recording');
      }
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };
  
  const activateWebcam = async () => {
    if (!videoStream) { // Prevent reinitialization
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setVideoStream(stream);
        setIsWebcamActivated(true);
      } catch (error) {
        console.error('Error accessing webcam:', error);
        alert('Could not access the webcam.');
      }
    }
  };
  
  const deactivateWebcam = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setIsWebcamActivated(false);
    }
  };
  const startRecording = async () => {
    if (!isWebcamActivated) {
      console.error("Cannot start recording: Webcam is not activated.");
      return;
    }
    try {
      const response = await fetch('http://127.0.0.1:5000/user/start_record', { method: 'POST' });
      if (response.ok) {
        setIsRecording(true);
        console.log('Started recording');
      } else {
        console.error('Failed to start recording');
      }
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };
  
  // const handleInputChange = (e) => {
  //   setInputMessage(e.target.value);
  //   if (isWebcamActivated && !isRecording) {
  //     console.log("recording working");
  //     startRecording();
  //   }
  //   clearTimeout(typingTimeoutRef.current);
  //   typingTimeoutRef.current = setTimeout(() => {
  //     stopRecording();
  //   }, 2800);
  // };
  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  
    if (!isRecording && videoStream) {
      startRecording();
    }
  
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      if (isRecording) {
        stopRecording();
      }
    }, 2000);
  };
      const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      setMessages([...messages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');

      // buffer messages and send them in one go after delay
      clearTimeout(sendTimeoutRef.current);
      sendTimeoutRef.current = setTimeout(async () => {
        await sendBufferedMessages();
        stopRecording(); 
      }, 2800);
    }
  };
  
    
  async function sendBufferedMessages() {
    try {
      setIsTyping(true); // Show typing indicator
      const bufferResponse = await fetch(
        `http://127.0.0.1:5000/text/buffer?user_input=${encodeURIComponent(inputMessage)}`,
        { method: 'POST' }
      );
  
      if (bufferResponse.ok) {
        const replyResponse = await fetch('http://127.0.0.1:5000/text/get_reply', { method: 'GET' });
        if (replyResponse.ok) {
          const replyText = await replyResponse.text();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: replyText, sender: 'assistant' },
          ]);
        } else {
          console.error('Failed to fetch response from backend');
        }
      } else {
        console.error('Failed to buffer user input');
      }
    } catch (error) {
      console.error('Error during message handling:', error);
    } finally {
      setIsTyping(false);
    }
  }
    

  return (
    <div className="analysis">
      <header className="analysis-header">
        <Link to="/">
          <img src="/logo.png" alt="Logo" className="analysis-logo" />
        </Link>
        {userData ? (
          <img
            src="/user-icon.png"
            alt="Profile"
            className="profile-picture"
            onClick={() => navigate('/profile')}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <div className="sign-in-analysis" onClick={() => navigate('/login')}>Sign In</div>
        )}
      </header>
      <div className="analysis-body">
        {/* {!hasStarted ? (
          <>
            <div className="default-body">
              <div className="title-container">
                <p style={{fontSize:'20px'}}>Welcome to</p>
                <p className="title-text">EchoAI</p>
                <p className="text">Understand and manage your emotions effortlessly with insights, tracking, and personalized guidance.</p>
              </div>
              <button className="assessment-button" onClick={() => { chatStart(); activateWebcam(); }}>Start assessment</button>
              <div className="card-container">
                <div className="card">
                  <img src="/emotional-analysis-img.png" className="emotional-analysis-img"/>
                  <div style={{padding: '20px'}}>
                  <div className="mood-tracking-title">
                    <img src="/smily-icon.png" className="smily-icon"/>
                    <h3>Emotion Analysis</h3>
                  </div>
                  <div>
                    <p>Get a deep understanding of your emotions with our advanced analysis tools.</p>
                  </div>
                  </div>
                </div>
                <div className="card">
                  <img src="/mood-tracking.png" className="mood-tracking"/>
                  <div style={{padding: '20px'}}>
                    <div className="mood-tracking-title">
                      <img src="/graph-icon.png" className="graph-icon"/>
                      <h3>Mood Tracking</h3>
                    </div>
                    <div>
                      <p>Track your mood over time and identify patterns to be better manage your mental well-being.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <> */}
            <div className="video-container">
              {!isWebcamActivated ? (
                <div className="deactivate-video-container">
                  <div className="placeholder-container">
                    <p style={{color:"#8C8C8C"}}>Webcam is not activated. Start chatting to start the video feed.</p>
                  </div>
                </div>
              ) : (
                <div className="activate-video-container">
                    <video
                      ref={(videoElement) => {
                        if (videoElement && !videoElement.srcObject) {
                          videoElement.srcObject = videoStream;
                          videoElement.play();
                        }
                      }}
                      className="video-feed"
                    ></video>
                  <div className="corner top-left"></div>
                  <div className="corner top-right"></div>
                  <div className="corner bottom-left"></div>
                  <div className="corner bottom-right"></div>
                </div>
              )}
            </div>
            <div className="chat-box">
              <div className="head-chat-box">
                <h2 className="h2">Chat</h2>
              </div>
              <div className="messages">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  {msg.sender === 'assistant' && (
                    <img src="/chat-avatar.png" alt="Assistant Avatar" className="avatar-icon" />
                  )}
                  <div className="message-content">{msg.text}</div>
                </div>
              ))}
              {isTyping && (
                <div className="message assistant">
                  <img src="/avatar-3.png" alt="Assistant Avatar" className="avatar-icon" />
                  <div className="message-content typing-indicator">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
              <form onSubmit={handleSendMessage} className="chat-input-form">
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder="Type a message..."
                    value={inputMessage}
                    onChange={handleInputChange}
                    className="chat-input"
                  />
                  <img
                    src="./smile-icon.png"
                    className="emoji-button"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    alt="Emoji Picker"
                  />
                  {showEmojiPicker && (
                    <div className="emoji-picker-popup">
                      <button className="close-popup" onClick={() => setShowEmojiPicker(false)}>&times;</button>
                      <EmojiPicker onEmojiClick={(emojiData) => setInputMessage(inputMessage + emojiData.emoji)} />
                    </div>
                  )}
                  <button type="submit" className="send-button">➤</button>
                </div>
              </form>
            </div>
          {/* </> */}
        {/* )} */}
      </div>
    </div>
  );
}

export default Analysis;